@use '@/styles/utils/mixins.scss' as *;

.helpCardContainer {
  background-color: $primary;

  border-radius: 1.25rem;
  padding: 6.25rem 0;

  @include tab() {
    padding: 2.5rem 0;
  }

  .helpCardContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    color: $white;

    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }

    .leftContent {
      color: $white;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      grid-column: span 2;

      @include mob() {
        grid-column: span 1;
      }

      .heading {
        max-width: 39.25rem;
      }

      .description {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.525rem;
        max-width: 35.625rem;
      }

      :global {
        .subTitle {
          color: $white;
        }
      }
    }

    .rightContent {
      position: relative;
      text-align: right;

      @include tab() {
        text-align: center;
      }

      .cardImage {
        max-width: 100%;
        height: auto;
        border-radius: 1.25rem;
      }
    }
  }

  .contactContainer {
    padding: 1.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background-color: $white;
    color: $dark;
    max-width: 30rem;
    border-radius: 1.25rem;
  }

  .contactDescription {
    font-size: 1rem;
    color: $neutral08;
    font-weight: 300;
  }

  .ctaCardBtn {
    width: fit-content;
    margin-top: auto;

    @include mob() {
      width: 100%;
    }
  }
}
